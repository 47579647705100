import { get } from "lodash-es"
import { useUserStore } from "@/store/modules/user"

export const useIsAdmin = () => {
  const { userInfo } = useUserStore()

  return {
    isAdmin: Number(get(userInfo, 'userTypeNum')) !== 1
  }
}
