import type { App, Directive } from 'vue'
import { useUserStore } from '@/store/modules/user'
import { get } from 'lodash-es'

function hasAdmin(el: Element) {
  const { userInfo } = useUserStore()
  const flag: number = Number(get(userInfo, 'userTypeNum'))
  if (!(flag === 1)) {
    el.parentNode?.removeChild(el)
  }
}

const mounted = (el: Element) => {
  hasAdmin(el)
}

const permiDirective: Directive = {
  mounted
}

export const setupAdminDirective = (app: App<Element>) => {
  app.directive('hasAdmin', permiDirective)
}

export default permiDirective
