import request from '@/axios'
import type { UserType, UserImage, LoginType, UserInfoType } from './types'

export const loginApi = (data: UserType): Promise<LoginType> => {
  return request.post({ url: '/mechanism-admin-api/mechanism-admin/login', data })
}

export const loginOutApi = (): Promise<IResponse> => {
  return request.post({ url: '/mechanism-admin-api/mechanism-admin/logout' })
}

export const getInfoApi = (): Promise<UserInfoType> => {
  return request.get({ url: '/getInfo' })
}
export const getuserInfoApi = (userId): Promise<IResponse<UserImage>> => {
  return request.get({ url: `/system/user/${userId}` })
}
export const getRoutersApi = (params): Promise<IResponse<UserImage>> => {
  return request.get({ url: '/getRouters', params })
}
