import { get, indexOf, keys, split } from 'lodash-es'
import service, { PATH_URL } from './service'
import { CONTENT_TYPE } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'

const request = (option: AxiosConfig) => {
  const { url, method, params, data, headers, responseType } = option
  const local = {
    'mechanism-admin-api': {
      prot: 9811,
      url: 'mechanism'
    }
  }
  const key = split(url, '/')[1]
  const prot = indexOf(keys(local), key) === -1 ? 'mechanism-admin-api' : key
  const userStore = useUserStoreWithOut()
  let orgId = ''
  if (userStore.getUserInfo) {
    orgId = get(userStore.getUserInfo, 'orgId') || ''
  }
  const _params = params ? Object.assign(params, { orgId }) : params
  const _data = data ? Object.assign(data, { orgId }) : data
  return service.request({
    baseURL: import.meta.env.MODE === 'native' ? `${PATH_URL}:${local[prot]['prot']}` : undefined,
    url: `/${local[prot]['url']}${url}`,
    method,
    params: _params,
    data: _data,
    responseType: responseType,
    headers: {
      'Content-Type': CONTENT_TYPE,
      [userStore.getTokenKey ?? 'Authorization']: userStore.getToken ?? '',
      ...headers
    }
  })
}

export default {
  get: <T = any>(option: AxiosConfig) => {
    return request({ method: 'get', ...option }) as Promise<IResponse<T>>
  },
  post: <T = any>(option: AxiosConfig) => {
    return request({ method: 'post', ...option }) as Promise<IResponse<T>>
  },
  delete: <T = any>(option: AxiosConfig) => {
    return request({ method: 'delete', ...option }) as Promise<IResponse<T>>
  },
  put: <T = any>(option: AxiosConfig) => {
    return request({ method: 'put', ...option }) as Promise<IResponse<T>>
  },
  cancelRequest: (url: string | string[]) => {
    return service.cancelRequest(url)
  },
  cancelAllRequest: () => {
    return service.cancelAllRequest()
  }
}
